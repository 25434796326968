import api from './index'
// axios
import request from '@/utils/request'
// 获取资质查询条件
export function getRelevancy (params) {
  return request({
    url: api.GetRelevancy,
    method: 'get',
    params,
    hideloading: true
  })
}
export function getAllArtisanRelevancy (params) {
  return request({
    url: api.GetAllArtisanRelevancy,
    method: 'get',
    params,
    hideloading: true
  })
}
export function getMatchingAllMayHonorRelevancy (params) {
  return request({
    url: api.GetMatchingAllMayHonorRelevancy,
    method: 'get',
    params,
    hideloading: true
  })
}
export function getAdviceByCode (params) {
  return request({
    url: api.GetAdviceByCode,
    method: 'get',
    params,
    hideloading: true
  })
}
export function getCompanyRankByCode (params) {
  return request({
    url: api.GetCompanyRankByCode,
    method: 'get',
    params,
    hideloading: true
  })
}
export function getCompanyScoreByName (params) {
  return request({
    url: api.GetCompanyScoreByName,
    method: 'get',
    params,
    hideloading: true
  })
}
export function getProposalByCode (params) {
  return request({
    url: api.GetProposalByCode,
    method: 'get',
    params,
    hideloading: true
  })
}
export function getCountryBankScoreByNameUrl (params) {
  return request({
    url: api.GetCountryBankScoreByNameUrl,
    method: 'get',
    params,
    hideloading: true
  })
}
export function getCountryBankProposalByNameUrl (params) {
  return request({
    url: api.GetCountryBankProposalByNameUrl,
    method: 'get',
    params,
    hideloading: true
  })
}

export function getIpLocation (params) {
  return request({
    url: api.GetIpLocation,
    method: 'get',
    params,
    hideloading: true
  })
}
